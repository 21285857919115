import React from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { CButton, CCard, CCardBody, CForm, CFormInput } from '@coreui/react'
import Logo from 'src/assets/brand/thegrowhub_logo.png'
import { httpClient } from 'src/helpers/httpClient'
import { useMutation } from 'react-query'

export const Admin = () => {
  const formik = useFormik({
    initialValues: {
      organisationId: '',
      entityPublishingLimit: '',
      productPublishingLimit: '',
      batchPublishingLimit: '',
      batchTransferLimit: '',
      secretKey: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) =>
      mutation.mutate(
        //   console.log
        {
          organisation_id: values.organisationId,
          entity_publishing_limit: values.entityPublishingLimit,
          product_publishing_limit: values.productPublishingLimit,
          batch_publishing_limit: values.batchPublishingLimit,
          batch_transfer_limit: values.batchTransferLimit,
          secret_key: values.secretKey,
        },
      ),
  })

  const mutation = useMutation({
    mutationFn: (formData) => {
      httpClient.patch(`/organisations/${formik.values.organisationId}/limits/`, formData)
    },
  })

  return (
    <div style={{ backgroundColor: 'rgb(209 232 209)' }} className="min-vh-100">
      <div className="pt-5 text-center">
        <img src={Logo} alt="Logo" width={250} />
      </div>
      <div className="d-flex container justify-content-center align-items-center mt-5">
        <CCard className="p-3" style={{ maxWidth: '30rem' }}>
          <CCardBody>
            <CForm onSubmit={formik.handleSubmit}>
              <div className="mb-4">
                <CFormInput
                  label="Organisation Id"
                  name="organisationId"
                  values={formik.values.organisationId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                />
                {formik.touched.organisationId && formik.errors.organisationId && (
                  <div className="validation-error">Please enter a valid number (Integer).</div>
                )}
              </div>

              <div className="mb-4">
                <CFormInput
                  label="Entity publishing limit"
                  name="entityPublishingLimit"
                  value={formik.values.entityPublishingLimit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.entityPublishingLimit && formik.errors.entityPublishingLimit && (
                  <div className="validation-error">Please enter a valid number (Integer).</div>
                )}
              </div>

              <div className="mb-4">
                <CFormInput
                  label="Product publishing limit"
                  name="productPublishingLimit"
                  value={formik.values.productPublishingLimit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.productPublishingLimit && formik.errors.productPublishingLimit && (
                  <div className="validation-error">Please enter a valid number (Integer).</div>
                )}
              </div>

              <div className="mb-4">
                <CFormInput
                  label="Batch publishing limit"
                  name="batchPublishingLimit"
                  value={formik.values.batchPublishingLimit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.batchPublishingLimit && formik.errors.batchPublishingLimit && (
                  <div className="validation-error">Please enter a valid number (Integer).</div>
                )}
              </div>

              <div className="mb-4">
                <CFormInput
                  label="Batch transfer publishing limit"
                  name="batchTransferLimit"
                  value={formik.values.batchTransferLimit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.batchTransferLimit && formik.errors.batchTransferLimit && (
                  <div className="validation-error">Please enter a valid number (Integer).</div>
                )}
              </div>

              <div className="mb-4">
                <CFormInput
                  label="Secret key"
                  name="secretKey"
                  value={formik.values.secretKey}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>

              <div className="text-center mt-5">
                <CButton className="px-5" type="submit">
                  Submit
                </CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </div>
    </div>
  )
}

// Form validation with Yup
const validationSchema = Yup.object().shape({
  organisationId: Yup.number('aaa'),
  entityPublishingLimit: Yup.number().required('Required'),
  productPublishingLimit: Yup.number().integer(),
  batchPublishingLimit: Yup.number().integer(),
  batchTransferLimit: Yup.number().integer(),
})
